import { CabIcon } from "@CabComponents/CabIcon";
import { Popover, Box, Typography, IconButton, AvatarGroup, Divider } from "@mui/material";
import { ReactElement } from "react";
import { EventAttendee, Calendar, MeetingSlot, Leader } from "../../../store";
import colors from "../../../colors";
import CabAvatar from "@CabComponents/CabAvatar";
import { transformMarkupToText } from "@CabComponents/CabTextTokenInput";
import { ActionMenu } from "../../Meeting/ActionMenu";
import { 
  IoCheckmarkCircle, IoCloseCircle, IoHelpCircle, IoInformationCircle, IoClose, IoSquare, IoCalendarClearOutline, 
  IoPeopleOutline, IoBriefcaseOutline, IoTimerOutline, IoRepeat, IoLocationOutline
} from "react-icons/io5";


export interface Props {
  open: boolean;
  onClosePopover: () => void;
  popoverAnchorEl: HTMLDivElement | null;
  title: string;
  timeText?: string;
  locationText?: string;
  selectedDate: string;
  leaderInfo?: Leader[];
  calendarInfo?: Calendar[];
  attendees?: EventAttendee[];
  busy?: boolean;
  bufferStartMinutes?: number;
  bufferEndMinutes?: number;
  containedSlots?: MeetingSlot[];
  onEditMeeting?: (slotId: number, meetingId?: number) => void;
  onDeleteMeeting?: (meetingId: number) => void;
  onDuplicateMeeting?: (meetingId: number) => void;
  onShareMeeting?: (meetingId: number) => void;
  isRecurringEvent: boolean;
}

export const EventDetailPopover = ({
  open, onClosePopover, popoverAnchorEl, title, timeText, locationText, selectedDate, leaderInfo, calendarInfo,
  attendees, busy, bufferStartMinutes, bufferEndMinutes, containedSlots, onEditMeeting, onDeleteMeeting,
  onDuplicateMeeting, onShareMeeting, isRecurringEvent
}: Props): ReactElement => {

  const calendarColor = calendarInfo ? calendarInfo[0]?.backgroundColor : '';
  const calendars = calendarInfo?.map(calendar => 
    calendar.additionalCalendarEmail ? calendar.additionalCalendarEmail.name : calendar.summary) || [];
  const noResponseCount = attendees?.filter(a => a.responseStatus === 'needsAction').length || 0;
  const tentativeCount = attendees?.filter(a => a.responseStatus === 'tentative').length || 0;
  const declinedCount = attendees?.filter(a => a.responseStatus === 'declined').length || 0;
  const acceptedCount = attendees?.filter(a => a.responseStatus === 'accepted').length || 0;

  const statusIcon = (attendee: EventAttendee) => {
    const status = attendee.responseStatus;

    if (status === 'accepted') {
      return <CabIcon Icon={IoCheckmarkCircle} sx={{color: colors.greenPrimary}} alt='Accepted'/>;
    } else if (status === 'declined') {
      return <CabIcon Icon={IoCloseCircle} sx={{color: colors.redError}} alt='Declined'/>;
    } else if (status === 'tentative') {
      return <CabIcon Icon={IoHelpCircle} sx={{color: colors.coralLight}} alt='Maybe'/>;
    } else if (status === 'needsAction' || status === 'none') {
      return <CabIcon Icon={IoInformationCircle} sx={{color: colors.purplePrimary}} alt='Awaiting'/>;
    }
  };

  return (
    <Popover
      open={open}
      onClose={onClosePopover}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      anchorEl={popoverAnchorEl as Element}
    >
      <Box display='flex' flexDirection='column' gap={1} padding={'4px'} maxWidth="500px">
        <IconButton
          onClick={onClosePopover}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <CabIcon Icon={IoClose} alt='Close' />
        </IconButton>
        <Box display='flex' flexDirection='column' gap={2} padding={2}>
          {!containedSlots ? (
            <>
              <Box display='flex' gap={1} alignItems='flex-start'>
                <Box marginRight={2}>
                  <Box display="flex" gap={1}>
                    <CabIcon Icon={IoSquare} sx={{color: calendarColor, marginTop: '3px'}} 
                      alt={calendarInfo ? calendarInfo[0]?.summary : ''} />
                    <Typography variant="h5">{title}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    {isRecurringEvent ? (
                      <CabIcon Icon={IoRepeat} />
                    ) : <Box paddingLeft={2} />}
                    {timeText ? (
                      <Typography variant="body2" color={colors.black800}>
                        {`${selectedDate} at ${timeText}`}
                      </Typography>
                    ) : (
                      <Typography>{selectedDate}</Typography>
                    )}
                  </Box>

                  {locationText && (
                    <Box display="flex" alignItems="center" gap={1}>
                      <CabIcon Icon={IoLocationOutline} />
                      <Typography variant="body2" color={colors.black800}>
                        {locationText}
                      </Typography>
                    </Box>
                  )}

                  {leaderInfo && leaderInfo.length > 0 && (
                    <Box display='flex' alignItems='center' gap={1} marginLeft={3}>
                      <AvatarGroup max={10}>
                        {leaderInfo.map(leader => {
                          if (leader !== undefined) {
                            return (
                              <CabAvatar key={leader.id} name={`${leader.first_name} ${leader.last_name}`} 
                                src={leader.pic_url} color={leader.color} size='small' />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </AvatarGroup>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box display='flex' gap={1} alignItems='flex-start'>
                <CabIcon 
                  Icon={IoCalendarClearOutline} 
                  sx={{color: colors.black800, marginTop: '3px'}} 
                  alt='Calendars' 
                />
                <Box>
                  <Typography variant="body1" color={colors.black800}>{calendars.join(', ')}</Typography>
                </Box>
              </Box>
              {attendees && attendees?.length > 0 && (
                <Box>
                  <Box display='flex' gap={1} alignItems='flex-start'>
                    <CabIcon Icon={IoPeopleOutline} sx={{color: colors.black800, marginTop: '3px'}} alt='Attendees' />
                    <Box>
                      <Typography variant="body1" color={colors.black800}>
                        {`${attendees.length} guest(s)`}
                      </Typography>
                      {acceptedCount > 0 && (
                        <Typography variant="body2" color={colors.black800}>
                          {`${acceptedCount} yes`}
                        </Typography>
                      )}
                      {declinedCount > 0 && (
                        <Typography variant="body2" color={colors.black800}>
                          {`${declinedCount} no`}
                        </Typography>
                      )}
                      {tentativeCount > 0 && (
                        <Typography variant="body2" color={colors.black800}>
                          {`${tentativeCount} maybe`}
                        </Typography>
                      )}
                      {noResponseCount > 0 && (
                        <Typography variant="body2" color={colors.black800}>
                          {`${noResponseCount} awaiting`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box marginTop={1}>
                    {attendees.map((attendee: EventAttendee, i) => {
                      return <Box key={attendee.email + i} display='flex' gap={1} alignItems='center'>
                        {statusIcon(attendee)}
                        <Typography>
                          {attendee.displayName !== '' ? attendee.displayName : attendee.email}
                        </Typography>
                      </Box>;
                    })}
                  </Box>
                </Box>
              )}
              <Box display='flex' gap={1} alignItems='flex-start'>
                <CabIcon Icon={IoBriefcaseOutline} sx={{color: colors.black800, marginTop: '3px'}} alt='Free Busy' />
                <Box>
                  <Typography variant="body1" color={colors.black800}>
                    {busy ? 'Busy' : 'Free'}
                  </Typography>
                </Box>
              </Box>
              {(bufferStartMinutes || bufferEndMinutes) && (
                <Box>
                  <Box display='flex' gap={1} alignItems='flex-start'>
                    <CabIcon Icon={IoTimerOutline} sx={{color: colors.black800, marginTop: '3px'}} alt='Time Buffers' />
                    <Box>
                      <Typography variant="body1" color={colors.black800}>
                        Buffer Time
                      </Typography>
                      {bufferStartMinutes && (
                        <Typography variant="body2" color={colors.black800}>
                          Before: {bufferStartMinutes} minutes
                        </Typography>
                      )}
                      {bufferStartMinutes && (
                        <Typography variant="body2" color={colors.black800}>
                          After: {bufferEndMinutes} minutes
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box marginTop={1}>
              {containedSlots.map((slot, idx) => (
                <Box key={slot.id}>
                  {idx > 0 &&
                  <Divider sx={{marginTop: 1}}/>
                  }                    
                  <ActionMenu
                    isPoll={false}
                    onEdit={onEditMeeting && (() => onEditMeeting(slot.id, slot.meeting))}
                    onDelete={onDeleteMeeting && (() => onDeleteMeeting(slot.meeting))}
                    onDuplicate={onDuplicateMeeting && (() => onDuplicateMeeting(slot.meeting))}
                    onShare={onShareMeeting && (() => onShareMeeting(slot.meeting))}
                    horizontalPosition='right'
                    target={<Box width='100%' marginTop={1}>
                      <Typography>{transformMarkupToText(slot.title)}</Typography>
                    </Box>} />
                </Box>
              ))}
            </Box>
          )}
        </Box>
          
      </Box>
    </Popover>
  );
};

export default EventDetailPopover;