/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-len
// Initial code from https://raw.githubusercontent.com/jquense/react-big-calendar/fea41753a1d3e7b1140b5a94496eb035fa58d313/src/utils/layout-algorithms/overlap.js
import sortBy from 'lodash/sortBy';
import { RBCEvent } from '../../../store';

interface Accessors {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  start: (data: any) => Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  end: (data: any) => Date;
}

interface SlotMetrics {
  getRange: (start: Date, end: Date) => {
    start: number;
    startDate: Date;
    end: number;
    endDate: Date;
    top: number;
    height: number;
  };
}

class Event {
  start: number;
  end: number;
  startMs: number;
  endMs: number;
  top: number;
  height: number;
  data: RBCEvent;
  rows?: Event[];
  leaves?: Event[];
  container?: Event;
  row?: Event;
  isCabSlot: boolean;
  isCabEditable: boolean;

  constructor(data: RBCEvent, { accessors, slotMetrics }: { accessors: Accessors; slotMetrics: SlotMetrics }) {
    const { start, startDate, end, endDate, top, height } =
      slotMetrics.getRange(accessors.start(data), accessors.end(data));

    this.start = start;
    this.end = end;
    this.startMs = +startDate;
    this.endMs = +endDate;
    this.top = top;
    this.height = height;
    this.data = data;
    this.isCabSlot = !data.calendarInfo;
    this.isCabEditable = data.editable;
  }

  get _width(): number {
    if (this.rows) {
      const columns =
        this.rows.reduce(
          (max, row) => row.leaves ? Math.max(max, row.leaves.length + 1) : max, // add itself
          0
        ) + 1; // add the container

      return 100 / columns;
    }

    if (this.leaves) {
      const availableWidth = 100 - (this.container?._width || 0);
      return availableWidth / (this.leaves.length + 1);
    }

    return this.row?._width || 0;
  }

  get width(): number {
    const noOverlap = this._width;
    const overlap = Math.min(100, this._width * 1.7);

    if (this.rows) {
      return overlap;
    }

    if (this.leaves) {
      return this.leaves.length > 0 ? overlap : noOverlap;
    }

    if (!this.row || !this.row.leaves) {
      return 0;
    }
    
    const { leaves } = this.row;
    const index = leaves.indexOf(this);
    return index === leaves.length - 1 ? noOverlap : overlap;
  }

  get xOffset(): number {
    if (this.rows) return 0;

    if (this.leaves) return this.container?._width || 0;

    if (!this.row || !this.row.leaves) {
      return 0;
    }
    const { leaves, xOffset, _width } = this.row;
    const index = leaves.indexOf(this) + 1;
    return xOffset + index * _width;
  }
}

function onSameRow(a: Event, b: Event, minimumStartDifference: number): boolean {
  return (
    Math.abs(b.start - a.start) < minimumStartDifference ||
    (b.start > a.start && b.start < a.end)
  );
}

function sortByRender(events: Event[]): Event[] {
  // This is a Cabinet change to prioritize events as: editable > cab-slot > others (by start time)
  // i.e. Cabinet slots in currently selected meeting > cabinet slots from other meetings > calendar events
  const sortedByTime = sortBy(events, ['isCabEditable', 'isCabSlot', 'startMs', (e) => -e.endMs]);

  const sorted: Event[] = [];
  while (sortedByTime.length > 0) {
    const event = sortedByTime.shift()!;
    sorted.push(event);

    for (let i = 0; i < sortedByTime.length; i++) {
      const test = sortedByTime[i];

      if (event.endMs > test.startMs) continue;

      if (i > 0) {
        const nextEvent = sortedByTime.splice(i, 1)[0];
        sorted.push(nextEvent);
      }
      break;
    }
  }

  return sorted;
}

interface StyledEvent {
  event: RBCEvent;
  style: {
    top: number;
    height: number;
    width: number;
    xOffset: number;
  };
}

export default function getStyledEvents({
  events,
  minimumStartDifference,
  slotMetrics,
  accessors,
}: {
  events: RBCEvent[];
  minimumStartDifference: number;
  slotMetrics: SlotMetrics;
  accessors: Accessors;
}): StyledEvent[] {
  const proxies = events.map(
    (event) => new Event(event, { slotMetrics, accessors })
  );
  const eventsInRenderOrder = sortByRender(proxies);

  const containerEvents: Event[] = [];
  for (let i = 0; i < eventsInRenderOrder.length; i++) {
    const event = eventsInRenderOrder[i];

    const container = containerEvents.find(
      (c) =>
        c.end > event.start ||
        Math.abs(event.start - c.start) < minimumStartDifference
    );

    if (!container) {
      event.rows = [];
      containerEvents.push(event);
      continue;
    }

    event.container = container;

    let row: Event | null = null;
    for (let j = container.rows!.length - 1; !row && j >= 0; j--) {
      if (onSameRow(container.rows![j], event, minimumStartDifference)) {
        row = container.rows![j];
      }
    }

    if (row) {
      row.leaves!.push(event);
      event.row = row;
    } else {
      event.leaves = [];
      container.rows!.push(event);
    }
  }

  return eventsInRenderOrder.map((event) => ({
    event: event.data,
    style: {
      top: event.top,
      height: event.height,
      width: event.width,
      xOffset: Math.max(0, event.xOffset),
    },
  }));
}